import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { faAward, faLinkSlash, faStar, faXmark, faHeadSideVirus } from "@fortawesome/free-solid-svg-icons";
import api from "../../src/api/baseapi";
import { Link, useSearchParams } from "react-router-dom";
import Searchitem from '../../src/components/SearchItem'
import Pagename from '../../src/components/Pagename'
import Pagination from "react-js-pagination";
import Letter from "../components/Letter";
import Loader from "../components/Loader";
import Rajulcard from "../components/rijal/Rajulcard";
import Filtercounter from "../components/Filtercounter";


const Rijalfront = () => {
  const [rijal, setrijal] = useState([])
  const [loader, setloader] = useState(true)
  const [searchkey, settSearch] = useState(null);
  const {rajuls,rijalCount,resultPerPage,filteredRijalCount} = rijal;
  const [data, setData] = useState([]);
  // const {rajuls,rijalCount,resultPerPage,filteredRijalCount} = data
  // const [search, setSearch] = useState('')
  // console.log("rijalCount",rijalCount,resultPerPage,filteredRijalCount);
  // const [pageNo, setPageNo] = useState(1)
  let [searchParams] = useSearchParams();
  const filter = searchParams.get("filter");
  const search = searchParams.get("search");
  // const mudallis = searchParams.get("filter");
  const starred = searchParams.get("isStarred");
  const searched = searchParams.get("Search")
  const alphabet = searchParams.get("alphabet")
  const [currentPage, setCurrentPage] = useState(1);
  const setCurrentPageNo = (e) => {
    setCurrentPage(e);
  };
  const count = filteredRijalCount;
  
  console.log("search",searched)
  console.log("filter",filter)


  useEffect(() => {
     async function filterhandler(){
      if (filter !== null && filter === "sahabi") {
        return await api.get(`/rijal?isSahabi=${filter && true }&page=${currentPage}`)
          .then((res) => setrijal(res.data));
      }
      if (filter !== null && filter ==='starred') {
            return await api
              .get(`/rijal?isStarred=${filter && true}&page=${currentPage}`)
              .then((res) => setrijal(res.data));
          }
          if (filter !== null && filter ==='isMudallis') {
            return await api
              .get(`/rijal?isMudallis=${filter && true}&page=${currentPage}`)
              .then((res) => setrijal(res.data));
          }
          if (filter !== null && filter ==='isMukhtalit') {
            return await api
              .get(`/rijal?isMukhtalit=${filter && true}&page=${currentPage}`)
              .then((res) => setrijal(res.data));
          }
          if (filter !== null && filter ==='muaddal') {
            return await api
              .get(`/rijal?isRajul_type.isMuaddal=${filter && true}&page=${currentPage}`)
              .then((res) => setrijal(res.data));
          }
          if (filter !== null && filter ==='majrooh') {
            return await api
              .get(`/rijal?isRajul_type.isMajrooh=${filter && true}&page=${currentPage}`)
              .then((res) => setrijal(res.data));
          }
          if (filter !== null && filter ==='majhool') {
            return await api
              .get(`/rijal?isRajul_type.isMajhool=${filter && true}&page=${currentPage}`)
              .then((res) => setrijal(res.data));
          }
          if ( search !==null) {
            return await api
              .get(`/rijal?keyword=${search}`)
              .then((res) => setrijal(res.data));
          }
          if ( alphabet !==null) {
            return await api
              .get(`/rijal?alphabet=${alphabet}`)
              .then((res) => setrijal(res.data));
          }
          
          else{
            api.get(`/rijal?&page=${currentPage}`).then((res) => setrijal(res.data))
          }
  
    }
    filterhandler()
  
  }, [filter,search,alphabet,currentPage])



 
  console.log('rijals',rajuls)
  
  const id = 99999;
  const name = "মুহাম্মাদ বিন ইসমাঈল আল-বুখারী";
  const f_name = "ইমাম মুহাম্মাদ বিন ইসমাইল আল-বুখারী";
  const bd_date = { birth: "196", death: "264" };
  const isRajul_type = {
    isMuaddal: true,
    isMajrooh: false,
    isMajhool: false,
    isMixed: false,
  };
  const isStarred = true;
  const isSahabi = true;
  const item ={
    id:5,
    name:{arabic:'محمد بن إسماعيل بن إبراهيم بن المغيرة بن بذدزبة',bangla:'মুহাম্মাদ বিন ইসমাঈল বিন ইবরাহীম বিন আল-মুগিরাহ বিন বাযদাযবাহ'},
    f_name:{arabic:'محمد بن إسماعيل البخاري',bangla:'মুহাম্মাদ বিন ইসমাঈল আল-বুখারী'},
    bd_date: {
      birth: 202,
      death: 256
      },
    isSahabi: true,
    isRajul_type: {
      isMuaddal: true,
      isMajrooh: false,
      isMajhool: false,
      isMixed: false
      },
      isStarred: true,
  }
  return (!rajuls  ?  <Loader />:
    <>
    {
    <div className=" ">
     
     <div className="max-w-[700px] m-auto">
     <div className="mt-5 searchitem mb-2">
      <Searchitem
      placeholder={"রাবীর নাম লিখুন..."}
      handelsearch={(e) => settSearch(e.target.value)}
      searchkey={searchkey}
      
      />

{/* <Link to={`?search=${searchkey}`}> search</Link> */}
      </div>
     <div className="Letters">
          <Letter />
        </div>
      
     <div className="flex flex-col items-center flex-wrap gap-4 mt-5 sahabi gap-y-3 md:justify-center md:flex-row ">
      <div className="flex gap-1 btns md:gap-3">
      {/* <p className="relative m-16 group">
  <span>Hover over me</span>
  <span className="absolute -bottom-1  w-full h-1 bg-[#858585] group-hover:w-0 group-hover:transition-all"></span>
  {/* <span className="absolute w-0 h-2 bg-red-400 -bottom-1 right-1/2 group-hover:w-1/2 group-hover:transition-all"></span> */}
{/* </p> */} 
{/* <div className="others"></div> */}

<div className="flex gap-1  sahabi md:gap-3">
<Link className={`  p-[1px_17px] rounded-sm text-[#303030]  md:text-[18px] hover:bg-[#ffffff] relative shadow-[0px_1px_3px_0px_#e3e3e3] group  `} to={`?filter=sahabi`}> <FontAwesomeIcon icon="fa-solid fa-circle-check" className={` ${filter === 'sahabi' ? 'text-[#34ff78]': 'text-[#c9c9c9]'} md:text-[20px]  group-hover:text-[#34ff78]`} /> সাহাবী</Link>

        <Link className={`  p-[1px_17px] rounded-sm text-[#303030]  md:text-[18px] hover:bg-[#ffffff] relative shadow-[0px_1px_3px_0px_#e3e3e3] group  `} to={`?filter=starred`}><FontAwesomeIcon className={` ${filter === 'starred' ? 'text-[#34ff78]': 'text-[#c9c9c9]'} md:text-[20px]  group-hover:text-[#34ff78]`} icon={faStar} /> স্টার</Link>

</div>
  
        

        <div className="flex gap-1 mudallismukhtalidd md:gap-3">
          
        <Link className={`  p-[1px_17px] rounded-sm text-[#303030]  md:text-[18px] hover:bg-[#ffffff] relative shadow-[0px_1px_3px_0px_#e3e3e3] group  `}  to={`?filter=isMudallis`}><FontAwesomeIcon className={` ${filter === 'isMudallis' ? 'text-[#34ff78]': 'text-[#c9c9c9]'} md:text-[20px]  group-hover:text-[#34ff78]`} icon={faLinkSlash} /> মুদাল্লিস</Link>

        <Link className={`  p-[1px_17px] rounded-sm text-[#303030]  md:text-[18px] hover:bg-[#ffffff] relative shadow-[0px_1px_3px_0px_#e3e3e3] group  `} to={`?filter=isMukhtalit`}><FontAwesomeIcon className={` ${filter === 'isMukhtalit' ? 'text-[#34ff78]': 'text-[#c9c9c9]'} md:text-[20px]  group-hover:text-[#34ff78]`} icon={faHeadSideVirus} /> মুখতালিত</Link>
        </div>


        </div>
    
        
        
        <div className="flex gap-5 jarahtadil">

        <Link  to={`?filter=muaddal`}>
        <div className="flex items-center gap-3 group ismuaddal">
               <div className={`circle group-hover:border-[#00000029] group-hover:border-[5px] ${filter === 'muaddal' ? 'border-[#00000029] border-[5px]': ''}  h-[20px] w-[20px] bg-[#23ffda] rounded-full `}></div>

                <p className=' text-[#303030] text-[18px]  '>মুআদ্দাল</p> 
        </div></Link>
        <Link  to={`?filter=majrooh`}>
        <div className="flex items-center gap-3 group ismudallis">
               <div className={`circle ${filter === 'majrooh' ? 'border-[#00000029] border-[5px]': ''} group-hover:border-[#00000029] group-hover:border-[5px]  h-[20px] w-[20px]  bg-[#ff3c1a] rounded-full `}></div>
                <p className=' text-[#303030] text-[18px]  '>মাজরুহ</p> 
        </div></Link>
        <Link  to={`?filter=majhool`}>
        <div className="flex items-center gap-3 group ismudallis">
               <div className={`circle ${filter === 'majhool' ? 'border-[#00000029] border-[5px]': ''} group-hover:border-[#00000029] group-hover:border-[5px]  h-[20px] w-[20px]  bg-[#ffd415] rounded-full `}></div>
                <p className=' text-[#303030] text-[18px]  '>মাজহুল</p> 
        </div></Link>
        
               
               
          
        
        
        </div>

      </div>
      <div className="filtercou mb-4">
      <Filtercounter value={(filter || search)} totalCount={rijalCount} text="রাবী" filterCount={filteredRijalCount} resultprpage={resultPerPage} />

      </div>
      




     
{ rajuls && rajuls.map((item,index)=>
    <Rajulcard item={item} />
    )}
     {resultPerPage < count && (
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={resultPerPage}
              totalItemsCount={count}
              onChange={setCurrentPageNo}
              prevPageText="Prev"
              firstPageText="1st"
              lastPageText="Last"
              nextPageText="Next"
              itemClass="page_item"
              linkClass="page_link"
              activeClass="pageItemActive"
              activeLinkClass="pageLinkActive"
            />
          )}
     </div>
     
     
           
           
    

    
  </div> }
    
    

      
    </>
  );
};

export default Rijalfront;
