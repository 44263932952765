import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "react-headless-accordion";
import { Link, NavLink, Outlet, Route, Routes } from 'react-router-dom';
import { toast } from 'react-toastify';
import Context from '../../Context';
import Login from '../components/Login';
import Sidebar from '../components/Sidebar';
import AddRajul from './AddRajul';

const Dashboard = () => {
    const sideList = [
        {parenthead: 'Tahqeeq',
        children:[{
            headname:'List',
            link:'narrations',
            icon:'fa-solid fa-people-group'
        },{
            headname:'Create',
            link:'addnarration',
            icon:'fa-solid fa-border-all'
        }],
        icon:'fa-solid fa-magnifying-glass-chart',
    
    
    },
    {parenthead: 'Rijal',
        children:[{
          headname:'Narrators',
          link:'narrators',
          icon:'fa-solid fa-people-group'
      },{
            headname:'Create',
            link:'addrajul',
            icon:'fa-solid fa-people-group'
        }],
        icon:'fa-solid fa-people-group',
    
    
    },
    {parenthead: 'Research Paper',
        children:[{
            headname:'Create',
            icon:'fa-solid fa-people-group'
        },{
            headname:'Create',
            icon:'fa-solid fa-people-group'
        }],
        icon:'icon="fa-solid fa-newspaper',
    
    
    },
    {parenthead: 'Category',
        children:[{
            headname:'Create',
            icon:'fa-solid fa-people-group'
        },{
            headname:'Create',
            icon:'fa-solid fa-people-group'
        }],
        icon:'fa-solid fa-diagram-project',
    
    
    }
    ]
    const {adminData:{success=false,user}, getmyData, setMessage} = useContext(Context)
    console.log({success})
    const isAuthenticate = false;
    const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

    const handleLogout = async () =>{
        await  axios.delete(
            "https://ahle-tahqiq-backend.onrender.com/api/user/logout",
            { withCredentials: true }
          );
        toast.success("Logged out")
        setMessage('')
        getmyData()
      }


  return (
<div>
  <div className='mt-[80px]'></div>
<div className="nav_content  text-[20px] text-[#40464e]  font-[500]  shadow-[1px_1px_4px_1px_rgb(0_0_0_/_10%)]   fixed  border-b-2  n md:px-[80px] py-2 items-center z-[10] border-[#ffffff] bg-[#ffffff]  top-0 left-0 right-0">


<div className="logo flex items-center  h-[40px]">

            <div className="ham_menu  p-6  rounded-[50px] absolute ml-4 cursor-pointer hamburger_icon w-[fit-content] "
            onClick={handleClick}>       
                  <p className="absolute translate-y-[-50%] translate-x-[-50%]">
                    {click ? <FontAwesomeIcon icon={faXmark} />  : <FontAwesomeIcon icon={faBars} />}
                    
                  </p>
            </div>


            <div className="w-full text-center logo_img ">
              <Link to="/">
                <p className="font-['akhandBangali'] text-[25px]">
                  AHLE TAHQEEQ
                </p>
              </Link>
            </div>
          </div>
  
</div>

    <div className='flex justify-center dashboard' >
        {!success ? <Login />: 
        <div> 
          


        
        
        <div className= {
              click
                ? "    md:w-[0]   "
                : "  md:w-[300px]   "
            }>


          <div
            // className={
            //   click
            //     ? "    absolute md:fixed md:w-[300px] left-0 right-0 transition-[all_0.3s_ease-out;]  z-[1]    "
            //     : " w-[100%] border-t border-white absolute left-[-100%] transition-[all_0.5s_ease-out;]    md:w-[inherit]  "
            // }


            className={
              click
                ? "    w-[100%] border-t border-white absolute md:left-[-100%] transition-[all_0.5s_ease-out;]    md:w-[inherit]    "
                : "   absolute md:fixed md:w-[300px] md:left-0 left-[-100%] md:right-0 transition-[all_0.3s_ease-out;]  z-[1]  "
            }
            // className={
            //   click
            //     ? "block   fixed z-40  left-0 right-0 top-0 bottom-0  md:relative  "
            //     : "hidden  md:block"
            // }
            // className={
            //   click
            //     ? "lala md:relative "
            //     : "lalu md:block "
            // }
          >
            <div className="flex nav_c ">
              <ul className="bg-[#eeeeee]  md:w-full w-[70%] h-[100vh] relative  ">
                <div className="n_lists">
                <div onClick={handleLogout} className="cursor-pointer hover:scale-[1.05] hover:translate-x-[-5px] flex items-center gap-1 py-3 px-5 bg-blue-400 rounded my-2 transition-all duration-300 ease-in-out ">
            <FontAwesomeIcon icon="fa-solid fa-gauge-high" />
            <p>Logout</p>
          </div>
          
        <NavLink className={(navData)=>navData.isActive ? 'text-[#ff3737]' : 'text-[#ffffff]'} to="/admin">
          <div className="cursor-pointer hover:scale-[1.05] hover:translate-x-[-5px] flex items-center gap-1 py-3 px-5 bg-white rounded my-2 transition-all duration-300 ease-in-out ">
            <FontAwesomeIcon icon="fa-solid fa-gauge-high" />
            <p>Dashboard</p>
          </div>
        </NavLink>
                  
                <Accordion>
            {sideList.map((i)=> <AccordionItem className='bg-[#e8ff1b] w-full'>
        <AccordionHeader className="flex items-center justify-between w-full p-4 my-2 text-gray-600 transition-all duration-300 ease-in-out bg-white border-b rounded"> 
        <div className="flex ">
        <FontAwesomeIcon className="mt-1" icon={i.icon} />
            <p>&nbsp; {i.parenthead}</p>
          </div>
        </AccordionHeader>
    
        <AccordionBody>
            <div className="accordion-body">
                {i.children.map((c)=> <NavLink className={(navData)=>navData.isActive ? 'text-[#fff000]' : 'text-[#ffffff]'} to={c.link}>
            <div className="bg-[#150f25] hover:bg-[#1e173346] transition-all duration-300 flex justify-between items-center p-2 my-2 rounded">
              <p>
                <FontAwesomeIcon icon={c.icon} /> 
                <span className="ml-1"> {c.headname}</span>
              </p>
            </div>
          </NavLink>)}                
            </div>
        </AccordionBody>
    </AccordionItem>

    
    
    
    )}

    { user && user.role === 'admin' &&
    <AccordionItem className='bg-[#e8ff1b] w-full'>
        <AccordionHeader className="flex items-center justify-between w-full p-4 my-2 text-gray-600 transition-all duration-300 ease-in-out bg-white border-b rounded"> 
        <div className="flex ">
        <FontAwesomeIcon className="mt-1" icon={'fa-solid fa-people-group'} />
            <p>User</p>
          </div>
        </AccordionHeader>
    
        <AccordionBody>
            <div className="accordion-body">
                <NavLink className={(navData)=>navData.isActive ? 'text-[#fff000]' : 'text-[#ffffff]'} to={'feer'}>
            <div className="bg-[#150f25] hover:bg-[#1e173346] transition-all duration-300 flex justify-between items-center p-2 my-2 rounded">
              <p>
                <FontAwesomeIcon icon={'fa-solid fa-people-group'} />
                <span className="ml-1">{'create'}</span>
              </p>
            </div>
          </NavLink>               
            </div>
        </AccordionBody>
    </AccordionItem>}
    
    </Accordion>
                  <div
                    className="footer md:hidden absolute bottom-[70px] justify-between items-center bg-[#313131] p-[15px_25px] font-['monoserrat']  w-full
            "
                  >
                    <div className="logo w-[70px] cursor-pointer">
                      <img src="img/Component4_1.png" alt="" />
                    </div>
                    <h2 className="text-white text-[10px]">
                      {" "}
                      2022 | All right reserved.
                    </h2>
                  </div>
                </div>
                {/* <div className="footer md:hidden  flex justify-between items-center bg-[#0e122d] p-[1px_25px] fixed bottom-0 w-full
            ">
              <div className="logo w-[70px] cursor-pointer">
                <img src="img/Component4_1.png" alt="" />
              </div>
              <h2 className="text-white text-[10px]"> 2022 | All right reserved.</h2>
            </div> */}
              </ul>
              <div
                onClick={handleClick}
                // className="w-[30%] h-[100vh] blur_div bg-[#afafaf] opacity-25 md:hidden"
                className={
                  click
                    ? "w-[30%] h-[100vh] blur_div bg-[#afafaf] opacity-25 md:hidden  "
                    : "w-[30%] h-[100vh] blur_div bg-[#afafaf] opacity-0 md:hidden  "
                }
              ></div>
            </div>
          </div>
        </div> 
        
        
         </div> }
        <Outlet /> 


    </div>
    </div>
  )
}

export default Dashboard